import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {dsp, updateDsp} from '../../../store/dspSlice';

import HeaderBar from '../../../shared/components/HeaderBar';
import SiteHeader from '../../../shared/components/SiteHeader';
// import DspRetailerHeading from '../../../shared/components/DspRetailerHeading';
import StandardButton from '../../../shared/components/StandardButton';
import StepCounter from '../../../shared/components/StepCounter';
import VerifyCancel from '../../../shared/modals/VerifyCancel';

import {
  lockerClass,
  showAlert,
  showLoader,
  slotColor,
  getSlotNumber
} from '../../../shared/helpers/functions';
import {cancelOrder} from '../../../services/dsp';
import LockerDiagramAlt2 from "../../../shared/components/LockerDiagramAlt2";

const LockerOpened = () => {
  const [showVerifyCancel, setShowVerifyCancel] = useState(false);
  const [processing, setProcessing] = useState(false);

  const dispatch = useAppDispatch();
  const dspData = useAppSelector(dsp);
  const {
    siteData,
    selectedLocker,
    openSlots,
    orderSlots
  } = dspData;
  const navigate = useNavigate();

  const handleBackButton = async () => {
    // Your custom logic here
    const result = await cancelOrder();
    // You can add additional logic if needed
  };

  // Add an event listener for the 'popstate' event (back button click)
  useEffect(() => {
    window.addEventListener('popstate', handleBackButton);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  const handleCancel = async () => {
    // TODO: Add loaders when API is in place
    // setProcessing(true);
    // showLoader(true);
    const result = await cancelOrder();
    // setProcessing(false);
    // showLoader(false);

    const {success, message} = result;
    if (!success) {
      showAlert({
        visible: true,
        theme: 'error',
        message,
      });
      return;
    }

    // TODO: Should we assume here that the DSP
    // is providing delivery for the same retailer?
    dispatch(updateDsp({
      residentialUnitNumber: '',
      freshPostNumber: '',
      residents: null,
      selectedResident: null,
      orderId: '',
      openSlots: [],
      closedSlots: [],
    }));

    if (siteData != null && siteData.siteType === 'APARTMENT')
      navigate('../unit-number');
    else
      navigate('../fresh-post-number');

  };

  const handleSubmit = async () => {
    // Move all open lockers to the closed lockers array
    const orderSlotsUpdate = [
      ...openSlots,
      ...orderSlots
    ];
    dispatch(updateDsp({
      openSlots: [],
      orderSlots: orderSlotsUpdate
    }));

    navigate('../manage-lockers');
  };

  let openSlotsInfo;
  if (openSlots.length === 1) {
    const slot = openSlots[0];
    const slotNumber = getSlotNumber(selectedLocker!.width, slot.row, slot.column);
    const sc = slotColor('openLockerBox', slot, selectedLocker!.lockerType, openSlots, slot.row, slot.column);
    openSlotsInfo = (
      <div className="openLockersInfo">
        <p className="heading">
          {selectedLocker?.lockerType.toLowerCase()} locker opened
        </p>
        <div className="openLockerBoxContainer">
          <div className={sc.join(' ')}>
            <span className="boxNumber">
              {slotNumber}
            </span>
          </div>
        </div>
      </div>
    );
  } else {
    const slotNumbers = openSlots.map((slot) => {
      const {_id} = slot;
      return getSlotNumber(selectedLocker!.width, slot.row, slot.column);
    });
    openSlotsInfo = (
      <div className="openLockersInfo">
        <p className="heading">
          Lockers reopened
        </p>
        <div className="openLockerBoxContainer">
          {slotNumbers.map((slotNumber) => (
            <div className="openLockerBox">
              <span className="boxNumber">
                {slotNumber}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  }

  //const lockerMessage = `Please add ${selectedLocker!.slots.length  - openSlots.length> 0 ? 'any remaining items' : 'the order'} and close the locker${openSlots.length > 1 ? 's' : ''}.`
  const lockerMessage = `Please add any remaining items and close the locker.`

  return (
    <>
      <VerifyCancel
        visible={showVerifyCancel}
        confirm={handleCancel}
        cancel={() => setShowVerifyCancel(false)}
        processing={processing}
      />
      <div className="viewContainer LockerOpened">
        <div className="contentContainer">
          <HeaderBar />
          <SiteHeader site={siteData} />
          <div className="currentStepContainer">
            <StepCounter currentStep={3} />
          </div>
          <main className="content">
            {openSlotsInfo}
            <div className="lockerDiagramContainer">
              {openSlots && (
                <LockerDiagramAlt2
                  locker={selectedLocker!}
                  slots={openSlots}
                />
              )}
            </div>
            <p className="lockerMessage">
              {lockerMessage}
            </p>
          </main>
          <div className="actions">
            <div className="buttonContainer">
              <StandardButton 
                label="Next"
                handler={handleSubmit}
              />
            </div>
            <div className="buttonContainer addSpacing">
              <StandardButton 
                theme="secondary"
                label="Cancel"
                handler={() => setShowVerifyCancel(true)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LockerOpened;
