import React, {FormEvent, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {dsp, updateDsp} from '../../../store/dspSlice';

import HeaderBar from '../../../shared/components/HeaderBar';
import SiteHeader from '../../../shared/components/SiteHeader';

import StandardButton from '../../../shared/components/StandardButton';

type UpdateEnterRetailerFormFieldName = 'retailerName';

const EnterRetailer = () => {
  const [formData, setFormData] = useState({
    retailerName: '',
  });
  const [disableSubmit, setDisableSubmit] = useState(false);

  const dspData = useAppSelector(dsp);
  const dispatch = useAppDispatch();
  const {siteData, customRetailer} = dspData;
  const navigate = useNavigate();

  useEffect(() => {
    // Check if a value is stored in state on load
    if (customRetailer) {
      setFormData((prevState) => ({
        ...prevState,
        retailerName: customRetailer,
      }));
    }
  }, [customRetailer]);

  useEffect(() => {
    // Test form data
    const {retailerName} = formData;
    setDisableSubmit(!retailerName.trim());
  }, [formData]);

  const handleInput = (key: UpdateEnterRetailerFormFieldName, value: string) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleBack = () => {
    navigate(-1);
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    const customRetailer = formData.retailerName.trim();
    dispatch(updateDsp({
      customRetailer,
      selectedRetailer: null, // Clear out any existing data
    }));

    if (siteData != null && siteData.siteType === 'APARTMENT')
      navigate('../unit-number');
    else
      navigate('../fresh-post-number');
  }

  return (
    <div className="viewContainer EnterRetailer">
      <form className="contentContainer" onSubmit={handleSubmit}>
        <HeaderBar />
        <SiteHeader site={siteData} />
        <main className="content">
          <p className="heading">
            What retailer are you delivering for?
          </p>
          <div className="formItems">
            <div className="formItem">
              <label className="label" htmlFor="retailerName">
                Retailer Name
              </label>
              <div className="inputContainer">
                <input
                  className="input"
                  placeholder="Enter retailer name"
                  id="retailerName"
                  name="retailerName"
                  value={formData.retailerName}
                  onChange={(e) => handleInput('retailerName', e.target.value)}
                />
              </div>
            </div>
          </div>
        </main>
        <div className="actions">
          <div className="buttonContainer">
            <StandardButton 
              type="submit"
              label="Next"
              disabled={disableSubmit}
            />
          </div>
          <div className="buttonContainer addSpacing">
            <StandardButton 
              theme="secondary"
              label="Back"
              handler={handleBack}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default EnterRetailer;
