import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './store';

export interface LoaderState {
  visible: boolean;
}

export interface LoaderUpdate {
  visible?: boolean;
}

const initialState: LoaderState = {
  visible: false,
};

/**
 * Please note that we're using the Redux Toolkit here,
 * which is allowing us to assign new values to state using
 * the Immer library underneath the hood.
 * https://redux-toolkit.js.org/usage/usage-guide
 */
export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    updateLoader: (draftState, action: PayloadAction<LoaderState>) => {
      if (action.payload.visible !== undefined) {
        draftState.visible = action.payload.visible;
      }
    }
  },
});

export const {updateLoader} = loaderSlice.actions;

export const loader = (state: RootState) => state.loader;

export default loaderSlice.reducer;
