import React from 'react';

import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {alert, closeAlert} from '../../store/alertSlice';

import {Modal} from 'antd';
import StandardButton from '../components/StandardButton';

const Alert = () => {
  const dispatch = useAppDispatch();
  const alertState = useAppSelector(alert);

  const {visible, theme, message} = alertState;

  const handleClose = () => {
    dispatch(closeAlert());
  };

  let title: string;

  switch (theme) {
    case 'error':
      title = 'Error';
      break;
    case 'success':
      title = 'Success';
      break;
    default: 
      title = 'Alert';
  }

  // Build content
  const modalRender = () => (
    <div className="alertContainer">
      <div className="alert">
        <div className="content">
          <p className="heading">
            {title}
          </p>
          <p className="subHeading">
            {message}
          </p>
        </div>
        <div className="actions">
          <div className="buttonContainer">
            <StandardButton
              label="Close"
              theme="text"
              handler={handleClose}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      modalRender={modalRender}
      centered
      open={visible}
      onCancel={handleClose}
    />
  );
};

export default Alert;
