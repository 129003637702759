import React from 'react';

import {Modal} from 'antd';

import StandardButton from '../components/StandardButton';

interface VerifyCancelProps {
  visible: boolean;
  processing: boolean;
  confirm: () => Promise<void>;
  cancel: () => void;
}

const VerifyCancel = (props: VerifyCancelProps) => {
  const {visible, processing, confirm, cancel} = props;

  // Build content
  const modalRender = () => (
    <div className="alertContainer verifyCancel">
      <div className="alert">
        <div className="content">
          <p className="heading">
            Cancel Order?
          </p>
          <p className="subHeading">
            This will restart the delivery process.  Are you sure you want to cancel?  (If so, be sure to first remove all items from any lockers you may have opened!)
          </p>
        </div>
        <div className="actions">
          <div className="buttonContainer">
            <StandardButton
              label="Yes, Cancel"
              handler={confirm}
              processing={processing}
              disabled={processing}
            />
          </div>
          <div className="buttonContainer addSpacing">
            <StandardButton
              label="No, Do Not Cancel"
              theme="secondary"
              handler={processing ? () => console.log('Cannot cancel action.') : cancel}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      modalRender={modalRender}
      centered
      open={visible}
    />
  );
};

export default VerifyCancel;