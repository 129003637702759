import React from 'react';
import {useNavigate} from 'react-router-dom';

import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {dsp, updateDsp} from '../../../store/dspSlice';

import HeaderBar from '../../../shared/components/HeaderBar';
import SiteHeader from '../../../shared/components/SiteHeader';
import DspRetailerHeading from '../../../shared/components/DspRetailerHeading';
import StandardButton from '../../../shared/components/StandardButton';
import StepCounter from '../../../shared/components/StepCounter';
import LockerDiagramAlt2 from "../../../shared/components/LockerDiagramAlt2";

const OrderComplete = () => {
  const dispatch = useAppDispatch();
  const dspData = useAppSelector(dsp);
  const {
    siteData,
    selectedLocker,
    slots,
    selectedRetailer,
    residentialUnitNumber,
    freshPostNumber,
    selectedResident,
    openSlots,
  } = dspData;
  const navigate = useNavigate();

  const handleNewOrder = async () => {
    // TODO: Should we assume here that the DSP
    // is providing delivery for the same retailer?
    dispatch(updateDsp({
      residentialUnitNumber: '',
      freshPostNumber: '',
      residents: null,
      selectedResident: null,
      orderId: '',
      openSlots: [],
      closedSlots: [],
      orderSlots: [],
    }));

    if (siteData != null && siteData.siteType === 'APARTMENT')
      navigate('../unit-number');
    else
      navigate('../fresh-post-number');
  };

  const handleFinished = async () => {
    navigate('../finished');
  }

  let headingText = ""
  if (residentialUnitNumber !== "")
    headingText = `${selectedResident?.name} (Apt ${residentialUnitNumber})`;
  else
    headingText = `${selectedResident?.name} (FreshPost #: ${freshPostNumber})`;

  return (
    <div className="viewContainer OrderComplete">
      <div className="contentContainer">
        <HeaderBar />
        <SiteHeader site={siteData} />
        <div className="currentStepContainer">
          <StepCounter currentStep={4} />
        </div>
        <main className="content">
          <DspRetailerHeading
            selectedRetailer={selectedRetailer}
            text={headingText}
          />
          <div className="lockerDiagramContainer">
            {slots && (
              <LockerDiagramAlt2
                locker={selectedLocker!}
                slots={openSlots}
              />
            )}
          </div>
          <p className="lockerMessage">
            Drop off complete!
          </p>
        </main>
        <div className="actions">
          <div className="buttonContainer">
            <StandardButton 
              label="Drop Off Another Order"
              handler={handleNewOrder}
            />
            <br/><br/>
            <div className="buttonContainer">
              <StandardButton
                  theme="secondary"
                  label="I'm Finished"
                  handler={handleFinished}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderComplete;
