import React from 'react';
import {Link, useNavigate} from 'react-router-dom';

import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {dsp, updateDsp} from '../../../store/dspSlice';

import HeaderBar from '../../../shared/components/HeaderBar';
import StandardButton from '../../../shared/components/StandardButton';

const OrderComplete = () => {
  const dispatch = useAppDispatch();
  const dspData = useAppSelector(dsp);
  const navigate = useNavigate();

  return (
    <div className="viewContainer Finished">
      <div className="contentContainer">
        <HeaderBar />
        <main className="content center">
          <br/><br/>
          <h3>Thank you for dropping off an order!</h3>
          <br/><br/>
          <div className="buttonContainer" style={{margin: '20px'}}>
            <a href={"https://freshpost.us/"} target="_blank">
              <StandardButton
                  label="View More About FreshPost"
              />
            </a>
          </div>
        </main>
      </div>
    </div>
  );
};

export default OrderComplete;
