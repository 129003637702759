import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {useAppSelector} from '../../../store/hooks';
import {dsp} from '../../../store/dspSlice';

import {LockerType, Slot} from '../../../types/data';

import HeaderBar from '../../../shared/components/HeaderBar';
import SiteHeader from '../../../shared/components/SiteHeader';
import DspRetailerHeading from '../../../shared/components/DspRetailerHeading';
import StandardButton from '../../../shared/components/StandardButton';
import StepCounter from '../../../shared/components/StepCounter';
import LockerTypeButton from '../../../shared/components/LockerTypeButton';

import {showAlert, showLoader} from '../../../shared/helpers/functions';
import {completeOrder, openNewLocker, reopenAllLockers} from '../../../services/dsp';
import LockerDiagramAlt2 from "../../../shared/components/LockerDiagramAlt2";

const ManageLockers = () => {
  const [opening, setOpening] = useState(false);
  const [completing, setCompleting] = useState(false);
  const [reopening, setReopening] = useState(false);

  const dspData = useAppSelector(dsp);
  const {
    siteData,
    lockers,
    selectedLocker,
    slots,
    selectedRetailer,
    residentialUnitNumber,
    freshPostNumber,
    selectedResident,
    openSlots,
    closedSlots,
    orderId,
    orderSlots
  } = dspData;
  const navigate = useNavigate();

  const lockerTypes: LockerType[] = [];
  lockers?.forEach((locker) => {
    const {lockerType} = locker;
    if (lockerType && !lockerTypes.includes(lockerType) && locker.status === 'IN SERVICE') {
      lockerTypes.push(lockerType);
    }
  });

  const handleReopen = async () => {
    setReopening(true);
    const result = await reopenAllLockers();
    setReopening(false);

    const {success, message} = result;
    if (!success) {
      showAlert({
        visible: true,
        theme: 'error',
        message,
      });
      return;
    }

    navigate('../locker-opened');
  };

  const handleCompleteOrder = async () => {
    setCompleting(true);
    const result = await completeOrder();
    setCompleting(false);

    const {success, message} = result;
    if (!success) {
      showAlert({
        visible: true,
        theme: 'error',
        message,
      });
      return;
    }

    navigate('../order-complete');
  };

  const handleLockerTypeButton = async (lockerType: LockerType) => {
    // TODO: Add loaders when API is in place
    // setOpening(true);
    // showLoader(true);
    const result = await openNewLocker({lockerType});
    // setOpening(false);
    // showLoader(false);

    const {success, message} = result;
    if (!success) {
      showAlert({
        visible: true,
        theme: 'error',
        message,
      });
      return;
    }

    navigate('../locker-opened');
  };

  let headingText = ""
  if (residentialUnitNumber !== "")
    headingText = `${selectedResident?.name} (Apt ${residentialUnitNumber})`;
  else
    headingText = `${selectedResident?.name} (FreshPost #: ${freshPostNumber})`;

  const lockerButtons = lockerTypes.map((type) => {
    const handler = () => handleLockerTypeButton(type);
    return (
      <LockerTypeButton
        key={type}
        action="open"
        theme={type}
        handler={handler}
        disabled={opening}
      />
    );
  });

  const reopenButtonLabel = `Reopen Locker${orderSlots.length > 1 ? 's' : ''}`;

  return (
    <div className="viewContainer ManageLockers">
      <div className="contentContainer">
        <HeaderBar />
        <SiteHeader site={siteData} />
        <div className="currentStepContainer">
          <StepCounter currentStep={3} />
        </div>
        <main className="content">
          <DspRetailerHeading
            selectedRetailer={selectedRetailer}
            text={headingText}
          />
          <div className="lockerDiagramContainer">
            {orderSlots && (
              <LockerDiagramAlt2
                locker={selectedLocker!}
                slots={orderSlots!}
              />
            )}
          </div>
          <div className="moreOptions">
            <p className="header">
              Need more space?
            </p>
            <div className="lockerTypeButtons">
              {lockerButtons}
            </div>
          </div>
        </main>
        <div className="actions">
          <div className="buttonContainer">
            <StandardButton 
              label="Complete Order"
              handler={handleCompleteOrder}
              disabled={completing}
              processing={completing}
            />
          </div>
          <div className="buttonContainer addSpacing">
            <StandardButton 
              theme="secondary"
              label={reopenButtonLabel}
              handler={handleReopen}
              disabled={reopening}
              processing={reopening}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageLockers;
