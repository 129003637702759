import React from 'react';
import {RouterProvider} from 'react-router-dom';
import {router} from './router/router';

import Loader from './shared/modals/Loader';
import Alert from './shared/modals/Alert';

const App = () => {
  return (
    <>
      <Loader />
      <Alert />
      <RouterProvider router={router} />
    </>

  );
}

export default App;
