import React from 'react';
import {useNavigate} from 'react-router-dom';

import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {dsp, updateDsp} from '../../../store/dspSlice';

import HeaderBar from '../../../shared/components/HeaderBar';
import SiteHeader from '../../../shared/components/SiteHeader';
import RetailerLocationButton from '../../../shared/components/RetailerLocationButton';
import StandardButton from '../../../shared/components/StandardButton';

const SelectRetailerLocation = () => {
  const dspData = useAppSelector(dsp);
  const dispatch = useAppDispatch();
  const {siteData, retailers, selectedRetailer} = dspData;
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('../select-retailer'); // Navigate first to avoid UI updates
    dispatch(updateDsp({
      selectedRetailer: null,
    }));
  };

  const retailerLocationButtons: JSX.Element[] = [];

  retailers?.forEach((retailer) => {
    if (retailer.parentOrganization === selectedRetailer?.parentOrganization) {
      const handleButton = () => {
        dispatch(updateDsp({
          selectedRetailer: retailer,
        }));
        if (siteData != null && siteData.siteType === 'APARTMENT')
          navigate('../unit-number');
        else
          navigate('../fresh-post-number');
      };

      retailerLocationButtons.push(
        <RetailerLocationButton
          key={retailer._id}
          retailer={retailer}
          handler={handleButton}
        />
      );
    }
  });

  return (
    <div className="viewContainer SelectRetailerLocation">
      <div className="contentContainer">
        <HeaderBar />
        <SiteHeader site={siteData} />
        <main className="content">
          <div className="retailerLocationSelect">
            <p className="heading">
              What location are you delivering for?
            </p>
            <div className="retailerLocationList">
              {retailerLocationButtons}
            </div>
          </div>
        </main>
        <div className="actions">
          <div className="buttonContainer addSpacing">
            <StandardButton 
              theme="secondary"
              label="Back"
              handler={handleBack}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectRetailerLocation;
