import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { RootState } from './store';

export type AlertTheme = 'error' | 'success';

export interface AlertState {
  visible: boolean;
  theme: AlertTheme,
  message: string;
}

interface AlertUpdate {
  visible?: boolean;
  theme?: AlertTheme,
  message?: string;
}

export const initialState: AlertState = {
  visible: false,
  theme: 'error',
  message: '',
};

/**
 * Please note that we're using the Redux Toolkit here,
 * which is allowing us to assign new values to state using
 * the Immer library underneath the hood.
 * https://redux-toolkit.js.org/usage/usage-guide
 */
export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    updateAlert: (draftState, action: PayloadAction<AlertUpdate>) => {
      if (action.payload.visible !== undefined) {
        draftState.visible = action.payload.visible;
      }
      if (action.payload.theme !== undefined) {
        draftState.theme = action.payload.theme;
      }
      if (action.payload.message !== undefined) {
        draftState.message = action.payload.message;
      }
    },
    closeAlert: (draftState) => {
      draftState.visible = false;
      // NOTE: Leave the message and theme intact;
      // Any changes will be visible during animation
    },
  },
});

export const {updateAlert, closeAlert} = alertSlice.actions;

export const alert = (state: RootState) => state.alert;

export default alertSlice.reducer;
