import React from 'react';

import {Resident} from '../../types/data';

import {ChevronRight} from 'react-feather';

interface ResidentButtonProps {
  resident: Resident;
  handler: () => void;
  disabled?: boolean;
}

const ResidentButton = (props: ResidentButtonProps) => {
  const {resident, handler, disabled} = props;
  const {name} = resident;
  const isDisabled = !!disabled;

  return (
    <button className="residentButton" onClick={handler} disabled={isDisabled}>
      <span className="name">
        {name}
      </span>
      <span className="icon">
        <ChevronRight />
      </span>
    </button>
  );
};

export default ResidentButton;
