import React, {useEffect, useState} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';

import {dspSiteCheck} from '../shared/helpers/functions';
import ScrollToTop from '../shared/components/ScrollToTop';

/**
 * Implements business logic when the app is loaded.
 * Should likely display a logo during initialization.
 */
const Root = () => {
  const [initialized, setInitialized] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // TODO: Check for session; pull user data if session exists
  
    // Check for DSP
    const dspSiteCode = dspSiteCheck();
    if (dspSiteCode) {
      navigate(`/deliver?site=${dspSiteCode}`); // In the event we're not already there
    } else {
      // Show landing view
      navigate('/landing');
    }

    setInitialized(true);
  }, [navigate]);

  return initialized ? (
    <>
      <ScrollToTop />
      <Outlet />
    </>
  ) : null;
};

export default Root;
