import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './store';

import {Locker, Resident, Retailer, Site, Slot} from '../types/data';

interface DSPState {
  stationId: string;
  siteData: Site | null;
  lockers: Locker[] | null;
  selectedLocker: Locker | null;
  slots: Slot[] | null;
  retailers: Retailer[] | null;
  selectedRetailer: Retailer | null;
  customRetailer: string;
  residentialUnitNumber: string;
  freshPostNumber: string;
  residents: Resident[] | null;
  selectedResident: Resident | null;
  orderId: string;
  openSlots: Slot[];
  closedSlots: Slot[];
  orderSlots: Slot[];
}

interface DSPUpdate {
  stationId?: string;
  siteData?: Site | null;
  lockers?: Locker[] | null;
  selectedLocker?: Locker | null;
  slots?: Slot[] | null;
  retailers?: Retailer[] | null;
  selectedRetailer?: Retailer | null;
  customRetailer?: string,
  residentialUnitNumber?: string;
  freshPostNumber?: string;
  residents?: Resident[] | null;
  selectedResident?: Resident | null;
  orderId?: string;
  openSlots?: Slot[];
  closedSlots?: Slot[];
  orderSlots?: Slot[];
}

export const initialState: DSPState = {
  stationId: '',
  siteData: null,
  lockers: null,
  selectedLocker: null,
  slots: null,
  retailers: null,
  selectedRetailer: null,
  customRetailer: '',
  residentialUnitNumber: '',
  freshPostNumber: '',
  residents: null,
  selectedResident: null,
  orderId: '',
  openSlots: [],
  closedSlots: [],
  orderSlots: [],
};

/**
 * Please note that we're using the Redux Toolkit here,
 * which is allowing us to assign new values to state using
 * the Immer library underneath the hood.
 * https://redux-toolkit.js.org/usage/usage-guide
 */
export const dspSlice = createSlice({
  name: 'dspState',
  initialState,
  reducers: {
    updateDsp: (draftState, action: PayloadAction<DSPUpdate>) => {
      if (action.payload.stationId !== undefined) {
        draftState.stationId = action.payload.stationId;
      }
      if (action.payload.siteData !== undefined) {
        draftState.siteData = action.payload.siteData;
      }
      if (action.payload.lockers !== undefined) {
        draftState.lockers = action.payload.lockers;
      }
      if (action.payload.selectedLocker !== undefined) {
        draftState.selectedLocker = action.payload.selectedLocker;
      }
      if (action.payload.slots !== undefined) {
        draftState.slots = action.payload.slots;
      }
      if (action.payload.retailers !== undefined) {
        draftState.retailers = action.payload.retailers;
      }
      if (action.payload.selectedRetailer !== undefined) {
        draftState.selectedRetailer = action.payload.selectedRetailer;
      }
      if (action.payload.customRetailer !== undefined) {
        draftState.customRetailer = action.payload.customRetailer;
      }
      if (action.payload.residentialUnitNumber !== undefined) {
        draftState.residentialUnitNumber = action.payload.residentialUnitNumber;
      }
      if (action.payload.freshPostNumber !== undefined) {
        draftState.freshPostNumber = action.payload.freshPostNumber;
      }
      if (action.payload.residents !== undefined) {
        draftState.residents = action.payload.residents;
      }
      if (action.payload.selectedResident !== undefined) {
        draftState.selectedResident = action.payload.selectedResident;
      }
      if (action.payload.orderId !== undefined) {
        draftState.orderId = action.payload.orderId;
      }
      if (action.payload.openSlots !== undefined) {
        draftState.openSlots = action.payload.openSlots;
      }
      if (action.payload.closedSlots !== undefined) {
        draftState.closedSlots = action.payload.closedSlots;
      }
      if (action.payload.orderSlots !== undefined) {
        draftState.orderSlots = action.payload.orderSlots;
      }
    },
    resetDsp: (draftState) => {
      const {
        stationId,
        siteData,
        lockers,
        selectedLocker,
        slots,
        retailers,
        selectedRetailer,
        customRetailer,
        residentialUnitNumber,
        freshPostNumber,
        residents,
        selectedResident,
        orderId,
        openSlots,
        closedSlots,
        orderSlots
      } = initialState;

      draftState.stationId = stationId;
      draftState.siteData = siteData;
      draftState.lockers = lockers;
      draftState.selectedLocker = selectedLocker;
      draftState.retailers = retailers;
      draftState.slots = slots;
      draftState.selectedRetailer = selectedRetailer;
      draftState.customRetailer = customRetailer;
      draftState.residentialUnitNumber = residentialUnitNumber;
      draftState.freshPostNumber = freshPostNumber;
      draftState.residents = residents;
      draftState.selectedResident = selectedResident;
      draftState.orderId = orderId;
      draftState.openSlots = closedSlots;
      draftState.closedSlots = closedSlots;
      draftState.orderSlots = orderSlots;
    }
  },
});

export const {updateDsp, resetDsp} = dspSlice.actions;

export const dsp = (state: RootState) => state.dsp;

export default dspSlice.reducer;
