import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {dsp, updateDsp} from '../../../store/dspSlice';

import HeaderBar from '../../../shared/components/HeaderBar';
import SiteHeader from '../../../shared/components/SiteHeader';
import DspRetailerHeading from '../../../shared/components/DspRetailerHeading';
import StandardButton from '../../../shared/components/StandardButton';
import StepCounter from '../../../shared/components/StepCounter';

import {createOrder} from '../../../services/dsp';
import {showAlert, showLoader} from '../../../shared/helpers/functions';
import ResidentButton from '../../../shared/components/ResidentButton';

const SelectResident = () => {
  const [processing, setProcessing] = useState(false);

  const dspData = useAppSelector(dsp);
  const dispatch = useAppDispatch();
  const {siteData, selectedRetailer, residentialUnitNumber, residents} = dspData;
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // TODO: Updates to state?
  };

  const residentButtons = residents?.map((resident) => {
    const handleButton = async () => {
      setProcessing(true);
      showLoader(true);
      dispatch(updateDsp({
        selectedResident: resident,
      }));
      const result = await createOrder();
      showLoader(false);
      setProcessing(false);

      const {success, message} = result;
      if (!success) {
        showAlert({
          visible: true,
          theme: 'error',
          message,
        });
        return;
      }

      navigate('../locker-type');
    }

    return (
      <ResidentButton
        key={resident._id}
        resident={resident}
        handler={handleButton}
        disabled={processing}
      />
    );
  });

  const retailerHeadingText = `Which${residentialUnitNumber ? ` Apt ${residentialUnitNumber}` : ''} resident are you delivering to?`;

  return (
    <div className="viewContainer SelectResident">
      <div className="contentContainer">
        <HeaderBar />
        <SiteHeader site={siteData} />
        <div className="currentStepContainer">
          <StepCounter currentStep={1} />
        </div>
        <main className="content">
          <DspRetailerHeading
            selectedRetailer={selectedRetailer}
            text={retailerHeadingText}
          />
          <div className="residentList">
            {residentButtons}
          </div>
        </main>
        <div className="actions">
          <div className="buttonContainer addSpacing">
            <StandardButton 
              theme="secondary"
              label="Back"
              handler={handleBack}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectResident;
