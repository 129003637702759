import React from 'react';

import {useAppSelector} from '../../store/hooks';
import {loader} from '../../store/loaderSlice';

import {Modal} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

const Loader = () => {
  const loaderState = useAppSelector(loader);

  const {visible} = loaderState;

  // Build content
  const modalRender = () => (
    <div className="loaderContainer">
      <div className="loader">
        <div className="icon">
          <LoadingOutlined />
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      modalRender={modalRender}
      centered
      open={visible}
    />
  );
};

export default Loader;
