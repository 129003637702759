import {Locker, Slot} from "../../types/data";
import {getSlotNumber, lockerClass} from '../helpers/functions';

interface LockerDiagramAlt2Props {
  locker: Locker;
  slots: Slot[];
}

const LockerDiagramAlt2 = ( props: LockerDiagramAlt2Props ) => {
  const { locker, slots } = props;

  const { lockerType, width, height } = locker;

  // Create a flat array of lockers with their row and column indexes
  const slotArray = [];
  for (let rowIndex = 0; rowIndex < height; rowIndex++) {
    for (let colIndex = 0; colIndex < width; colIndex++) {
      slotArray.push({ row: rowIndex, col: colIndex });
    }
  }

  return (
      <div className="locker-container" style={{ gridTemplateColumns: `repeat(${width}, 1fr)` }}>
        {slotArray.map((slot, index) => {
          const lockerStyle = lockerClass(locker, lockerType, slots, slot.row, slot.col);
          const lockerNumber = getSlotNumber(width, slot.row, slot.col); // calculate the locker number
          return (
              <div key={index} className={lockerStyle.join(' ')}>
                <div>{lockerNumber}</div>
              </div>
          );
        })}
      </div>
  );
};

export default LockerDiagramAlt2;