import React from 'react';

import {LoadingOutlined} from '@ant-design/icons';

export type StandardButtonTheme = 'primary' | 'secondary' | 'text';

export interface StandardButtonProps {
  type?: 'button' | 'submit';
  label?: string;
  theme?: StandardButtonTheme;
  handler?: () => void;
  disabled?: boolean;
  processing?: boolean;
}

const StandardButton = (props: StandardButtonProps) => {
  const {type, label, theme, handler, disabled, processing} = props;
  const buttonType = type ?? 'button';
  const buttonText = label ?? 'Button';
  const buttonTheme: StandardButtonTheme = theme ?? 'primary';
  const buttonHandler = handler ? handler : () => console.log('Button pressed.');
  const isDisabled = !!disabled;
  const isProcessing = !!processing;
  const buttonClass = `standardButton ${buttonTheme}${isDisabled ? ' disabled' : ''}`;

  const onClick = isDisabled || buttonType === 'submit' ? undefined : buttonHandler;

  return (
    <button
      type={buttonType}
      className={buttonClass}
      onClick={onClick}
      disabled={isDisabled}
    >
      {isProcessing ? <LoadingOutlined style={{fontSize: 24}} /> : buttonText}
    </button>
  );
};

export default StandardButton;
