import React from 'react';
import {LockerType} from '../../types/data';

import ChillerIcon from '../../assets/icons/chiller-icon.png';
import FreezerIcon from '../../assets/icons/freezer-icon.png';
import AmbientIcon from '../../assets/icons/ambient-icon.png';

export interface LockerTypeButtonProps {
  action?: 'open' | 'add';
  theme?: LockerType;
  handler?: () => void;
  disabled?: boolean;
}

type LockerTypeButtonTheme = 'chiller' | 'freezer' | 'ambient';

const LockerTypeButton = (props: LockerTypeButtonProps) => {
  const {action, theme, handler, disabled} = props;

  const buttonAction = action ?? 'open';
  const buttonTheme = theme?.toLowerCase() as LockerTypeButtonTheme ?? 'ambient';
  const buttonText = `${buttonAction} ${buttonTheme} locker`;
  const buttonHandler = handler ? handler : () => console.log('Button pressed.');
  const isDisabled = !!disabled;

  const buttonClass = `lockerTypeButton ${buttonTheme}`;

  const onClick = isDisabled ? undefined : buttonHandler;

  let icon;
  let altText;

  switch (buttonTheme) {
    case 'chiller':
      icon = ChillerIcon;
      altText = 'White icon of bunch of grapes on green background';
      break;
    case 'freezer':
      icon = FreezerIcon;
      altText = 'White icon of ice cream cone on blue background';
      break;
    case 'ambient':
      icon = AmbientIcon;
      altText = 'White icon of stalk of wheat on gray background';
      break;
    default:
      icon = null;
  }

  return (
    <button
      type="button"
      className={buttonClass}
      onClick={onClick}
      disabled={isDisabled}
    >
      <span className="icon">
        {icon && <img src={icon} alt={altText} />}
      </span>
      <span className="label">
        {buttonText}
      </span>
    </button>
  );
};

export default LockerTypeButton;
