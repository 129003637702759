import React, {FormEvent, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {dsp, updateDsp} from '../../../store/dspSlice';

import HeaderBar from '../../../shared/components/HeaderBar';
import SiteHeader from '../../../shared/components/SiteHeader';
import DspRetailerHeading from '../../../shared/components/DspRetailerHeading';
import StandardButton from '../../../shared/components/StandardButton';
import StepCounter from '../../../shared/components/StepCounter';

import {createOrder, getResidentsByFreshPostNumber} from '../../../services/dsp';
import {showAlert} from '../../../shared/helpers/functions';

type EnterFreshPostNumberFormFieldName = 'freshPostNumber';

const EnterFreshPostNumber = () => {
  const [formData, setFormData] = useState({
    freshPostNumber: '',
  });
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [processing, setProcessing] = useState(false);

  const dspData = useAppSelector(dsp);
  const dispatch = useAppDispatch();
  const {siteData, selectedRetailer, freshPostNumber} = dspData;
  const navigate = useNavigate();

  useEffect(() => {
    // Check if a value is stored in state on load
    if (freshPostNumber) {
      setFormData((prevState) => ({
        ...prevState,
        freshPostNumber: freshPostNumber,
      }));
    }
  }, [freshPostNumber]);

  useEffect(() => {
    // Test form data
    const {freshPostNumber} = formData;
    setDisableSubmit(!freshPostNumber.trim());
  }, [formData]);

  const handleInput = (key: EnterFreshPostNumberFormFieldName, value: string) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleCancel = () => {
    // Presumably we're heading back to retailer select?
    navigate('../select-retailer'); // Navigate first to avoid UI updates
    dispatch(updateDsp({
      selectedRetailer: null,
      customRetailer: '',
      freshPostNumber: '',
      residents: null,
      selectedResident: null,
      orderId: '',
    }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    const freshPostNumber = formData.freshPostNumber.trim().toUpperCase();
    if(freshPostNumber.includes('FP-'))
      freshPostNumber.replace('FP-', '');

    dispatch(updateDsp({
      freshPostNumber,
    }));

    setProcessing(true);
    const getResidentsResult = await getResidentsByFreshPostNumber();

    const {success, message, data} = getResidentsResult;
    if (!success || !data) {
      setProcessing(false);
      showAlert({
        visible: true,
        theme: 'error',
        message,
      });
      return;
    }

    if (data.length > 1) {
      // Allow the user to select the appropriate customer
      setProcessing(false);
      navigate('../select-resident');
      return;
    }

    // There is only one customer at this address;
    // auto-select this customer and create order
    const selectedResident = data[0];
    dispatch(updateDsp({
      selectedResident,
    }));

    const createOrderResult = await createOrder();
    setProcessing(false);

    if (!createOrderResult.success) {
      showAlert({
        visible: true,
        theme: 'error',
        message: createOrderResult.message,
      });
      return;
    }

    navigate('../locker-type');
  }

  return (
    <div className="viewContainer EnterUnitNumber">
      <form className="contentContainer" onSubmit={handleSubmit}>
        <HeaderBar />
        <SiteHeader site={siteData} />
        <div className="currentStepContainer">
          <StepCounter currentStep={1} />
        </div>
        <main className="content">
          <DspRetailerHeading
            selectedRetailer={selectedRetailer}
            text="Enter customer's FreshPost number"
          />
          <div className="formItem">
            <label className="label" htmlFor="freshPostNumber">
              FreshPost #:
            </label>
            <div className="specialInputContainer">
              FP-&nbsp;<input
                className="input"
                type="text"
                placeholder="Enter FreshPost number"
                id="freshPostNumber"
                name="freshPostNumber"
                value={formData.freshPostNumber}
                onChange={(e) => handleInput('freshPostNumber', e.target.value)}
              />
            </div>
          </div>
        </main>
        <div className="actions">
          <div className="buttonContainer">
            <StandardButton 
              type="submit"
              label="Continue"
              disabled={disableSubmit || processing}
              processing={processing}
            />
          </div>
          <div className="buttonContainer addSpacing">
            <StandardButton 
              theme="secondary"
              label="Cancel"
              handler={handleCancel}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default EnterFreshPostNumber;
