import {store} from '../store/store';
import {updateDsp} from '../store/dspSlice';

import {Locker, LockerType, OpenedSlot, Order, Resident, Retailer, Site} from '../types/data';

export interface OpenLockerServiceParams {
  lockerType: LockerType;
}

const serverOrigin = process.env.REACT_APP_SERVER_ORIGIN ?? '';

export const loadDspSiteData = async () => {
  const appState = store.getState();
  const {stationId} = appState.dsp;

  if (!stationId) {
    return {
      success: false,
      message: 'Site code missing.'
    }
  }

  const getSiteData = async () => {
    const url = `${serverOrigin}sites/sitesByStationId?stationId=${stationId}`;

    const response = await fetch(url);
    if (response.status !== 200) {
      throw new Error('API error.');
    }

    const data = await response.json() as Site;
    // TODO: Add Zod to parse responses
    if (!data) {
      // API returns nothing if site cannot be found
      throw new Error('No site data found.');
    }

    return {
      siteData: data,
    };
  };

  const getSiteRetailers = async () => {
    const url = `${serverOrigin}sites/${stationId}/retailers/byStationId`;

    const response = await fetch(url);
    if (response.status !== 200) {
      throw new Error('API error.');
    }

    const data = await response.json() as Retailer[];
    // TODO: Add Zod to parse responses

    return {
      retailers: data,
    };
  };

  const getSiteLockers = async () => {
    const url = `${serverOrigin}sites/lockersAtSite?stationId=${stationId}`;

    const response = await fetch(url);
    if (response.status !== 200) {
      throw new Error('API error.');
    }

    const data = await response.json() as Locker[];

    // TODO: Add Zod to parse responses
    if (!data) {
      // API returns nothing if site cannot be found
      throw new Error('No locker configuration found.');
    }

    return {
      lockers: data,
    };
  };

  try {
    const values = await Promise.all([
      getSiteData(),
      getSiteRetailers(),
      getSiteLockers(),
    ]);

    let dspUpdate = {};

    values.forEach((val) => {
      dspUpdate = {
        ...dspUpdate,
        ...val,
      };
    });

    store.dispatch(updateDsp(dspUpdate));
    
    return {
      success: true,
      message: 'Site data loaded successfully.'
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: 'Failed to load site data.'
    }
  }
};

export const getResidentsByUnitNumber = async () => {
  const appState = store.getState();
  const {
    siteData,
    residentialUnitNumber,
  } = appState.dsp;

  const siteId = siteData?._id;

  if (!siteId) {
    return {
      success: false,
      message: 'Missing required site data.'
    }
  }

  if (!residentialUnitNumber) {
    return {
      success: false,
      message: 'Missing customer unit number.'
    }
  }

  try {
    const body = JSON.stringify({
      siteId,
      residentialUnitNumber,
    });
  
    const url = `${serverOrigin}sites/verifyUnitNumber`;
  
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    if (response.status !== 200) {
      throw new Error('API error.');
    }
  
    const data = await response.json() as Resident[];
    
    if (data.length === 0) {
      return {
        success: false,
        message: 'No registered customers found at that unit number.  Verify the unit number or contact the customer.'
      }
    }

    store.dispatch(updateDsp({
      residents: data,
    }));

    return {
      success: true,
      message: 'Resident data loaded successfully.',
      data,
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: 'Failed to load resident data.'
    }
  }
};

export const getResidentsByFreshPostNumber = async () => {
  const appState = store.getState();
  const {
    siteData,
    freshPostNumber,
  } = appState.dsp;

  freshPostNumber.toUpperCase().replace('FP-', '');

  const siteId = siteData?._id;

  if (!siteId) {
    return {
      success: false,
      message: 'Missing required site data.'
    }
  }

  if (!freshPostNumber) {
    return {
      success: false,
      message: 'Missing customer FreshPost number.'
    }
  }

  try {
    const body = JSON.stringify({
      siteId,
      freshPostNumber,
    });

    const url = `${serverOrigin}sites/verifyFreshPostNumber`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    if (response.status !== 200) {
      throw new Error('API error.');
    }

    const data = await response.json() as Resident[];

    if (data.length === 0) {
      return {
        success: false,
        message: 'No registered customers found with that FreshPost number. Contact the customer.'
      }
    }

    store.dispatch(updateDsp({
      residents: data,
    }));

    return {
      success: true,
      message: 'Resident data loaded successfully.',
      data,
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: 'Failed to load resident data.'
    }
  }
};


export const createOrder = async () => {
  const appState = store.getState();
  const {
    selectedResident,
    siteData,
    selectedRetailer,
    customRetailer,
  } = appState.dsp;

  const customerId = selectedResident?._id;
  const siteId = siteData?._id;
  const retailerLocationId = selectedRetailer?._id;
  const retailerDisplayName = selectedRetailer?.retailerDisplayName || customRetailer;

  if (!retailerDisplayName || (selectedRetailer && !retailerLocationId)) {
    return {
      success: false,
      message: 'Missing required retailer data.'
    }
  }

  if (!customerId) {
    return {
      success: false,
      message: 'Missing customer ID.'
    }
  }

  if (!siteId) {
    return {
      success: false,
      message: 'Missing required site data.'
    }
  }

  try {
    const body = JSON.stringify({
      userId: customerId,
      siteId,
      retailerDisplayName,
      retailerLocationId, // stringify() removes this if undefined
    });
  
    const url = `${serverOrigin}orders`;
  
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    if (response.status !== 200) {
      throw new Error('API error.');
    }
  
    const data = await response.json() as Order;
    const orderId = data._id;

    store.dispatch(updateDsp({
      orderId,
    }));

    return {
      success: true,
      message: 'New order created successfully.'
    };
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: 'Failed to create order.'
    }
  }
};

/**
 * OPEN NEW LOCKER
 * Attempts to open a new locker for the order
 * Assumes no currently open lockers
 */
export const openNewLocker = async (params: OpenLockerServiceParams) => {
  const {lockerType} = params;

  const appState = store.getState();
  const {lockers, orderId, closedSlots} = appState.dsp;

  if (!orderId) {
    return {
      success: false,
      message: 'Missing order ID.'
    }
  }

  try {
    const body = JSON.stringify({
      lockerType,
    });
  
    const url = `${serverOrigin}orders/${orderId}/requestSlot`;
  
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    if (response.status === 400) {
      return {
        success: false,
        message: 'No lockers available for the selected type.',
      }
    }

    if (response.status !== 200) {
      throw new Error('API error.');
    }
  
    const data = await response.json() as OpenedSlot;

    // TODO: DEVELOPMENT TEST CODE - START
    // const availableLockers = lockers?.filter((locker) => {
    //   const alreadyInUse = !!closedLockers.find((item) => {
    //     return item._id === locker._id;
    //   });
    //   return !alreadyInUse && locker.lockerType === lockerType;
    // });
    //
    // if (!availableLockers || availableLockers.length === 0) {
    //   return {
    //     success: false,
    //     message: 'No lockers available for the selected type.',
    //   }
    // }
    //
    // const data: OpenedLocker = {
    //   recommendedLocker: availableLockers[Math.floor(Math.random() * availableLockers.length)],
    // };
    // TEST DATA END

    const {recommendedSlot} = data;

    store.dispatch(updateDsp({
      openSlots: [recommendedSlot],
      selectedLocker: recommendedSlot.locker,
    }));

    return {
      success: true,
      message: 'Locker opened.',
    };
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: 'Failed to open slot.'
    }
  }
};

/**
 * REOPEN ALL LOCKERS
 * Attempts to reopen all closed lockers
 * Assumes no currently open lockers
 */
export const reopenAllLockers = async () => {
  const appState = store.getState();
  const {orderId, orderSlots} = appState.dsp;

  if (!orderId) {
    return {
      success: false,
      message: 'Missing order ID.'
    }
  }

  if (orderSlots.length === 0) {
    return {
      success: false,
      message: 'No lockers to open.'
    }
  }

  const reopenLocker = async (lockerId: string) => {
    const url = `${serverOrigin}orders/${orderId}/dspReopenLocker`;
  
    const body = JSON.stringify({
      lockerId,
    });

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    if (response.status !== 200) {
      throw new Error('API Error.');
    }

    return true;
  };

  try {
    // TODO: Code temporarily removed due to API errors
    // await Promise.all(closedLockers.map((locker) => {
    //   return reopenLocker(locker._id);
    // }));

    //store.dispatch(updateDsp({
    //  openSlots: [
    //    ...closedSlots,
    //  ],
      //closedSlots: []
    //}));

    return {
      success: true,
      message: 'Lockers opened successfully.'
    };
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: `An error occurred while opening the locker${orderSlots.length > 1 ? 's' : ''}.`
    }
  }
};

export const cancelOrder = async () => {
  const appState = store.getState();
  const {orderId} = appState.dsp;

  if (!orderId) {
    return {
      success: false,
      message: 'Missing order ID.'
    }
  }

  try {  
    const url = `${serverOrigin}orders/${orderId}/cancel`;
  
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status !== 200) {
      throw new Error('API error.');
    }

    return {
      success: true,
      message: 'Order canceled successfully.'
    };
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: 'Failed to cancel order.'
    }
  }
};

export const completeOrder = async () => {
  const appState = store.getState();
  const { orderId} = appState.dsp;

  if (!orderId) {
    return {
      success: false,
      message: 'Missing order ID.'
    }
  }

  try {  
    const url = `${serverOrigin}orders/${orderId}/readyForPickup`;
  
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status !== 200) {
      throw new Error('API error.');
    }

    return {
      success: true,
      message: 'Order completed successfully.'
    };
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: 'Failed to complete order.'
    }
  }
};
