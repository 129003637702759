import React from 'react';
import Logo from "../../../assets/images/freshpost-logo.png";

/**
 * Component displayed by default when the app loads.
 * TODO: Add perhaps a centered logo here?
 */
const Landing = () => {
  return (
    <div className="viewContainer">
      <div className="contentContainer">
        <main className="content center">
            <br/>
            <img width="300px" src={Logo} alt="FreshPost" />
            <br/><br/>
            <h3>Please Scan a Locker QR Code to Continue</h3>
        </main>
      </div>
    </div>
  );
};

export default Landing;
