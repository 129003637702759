import React from 'react';

import {Check} from 'react-feather';

interface StepCounterProps {
  totalSteps?: number;
  currentStep?: number; 
}

interface StepProps {
  stepNum: number;
  finalStep: boolean;
  completed: boolean;
  active: boolean;
  disabled?: boolean;
}

const Step = (props: StepProps) => {
  const {finalStep, stepNum, completed, active, disabled} = props;
  const isDisabled = !!disabled;

  const stepClass = `stepNum${isDisabled ? ' disabled' : ''}${completed ? ' completed' : ''}${active ? ' active' : ''}`;

  return (
    <>
      <div className={stepClass}>
        {finalStep ? <Check /> : stepNum}
      </div>
      { !finalStep && (
        <div className="stepDivider" />
      )}
    </>
  );
}

const StepCounter = (props: StepCounterProps) => {
  const {currentStep, totalSteps} = props;
  const totalNumberOfSteps = totalSteps ?? 4;
  const currentStepNumber = currentStep ?? 0;

  const steps = [];
  for (let i = 1; i <= totalNumberOfSteps; i++) {
    const finalStep = (i === totalNumberOfSteps);
    const active = (i === currentStepNumber);
    const completed = (i < currentStepNumber);

    steps.push(
      <Step
        key={i}
        finalStep={finalStep}
        stepNum={i}
        completed={completed}
        active={active}
       />
    );
  }

  return (
    <div className="stepCounter">
      {steps}
    </div>
  );
};

export default StepCounter;
