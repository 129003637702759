import React from 'react';

import {Retailer} from '../../types/data';
import FPIcon from '../../assets/images/freshpost-icon.png';

interface RetailerButtonProps {
  retailer: Retailer;
  handler: () => void;
}

const RetailerButton = (props: RetailerButtonProps) => {
  const {retailer, handler} = props;
  const {parentOrganization, retailerDisplayName, logoUrl} = retailer;

  const buttonImageStyle = logoUrl ? ({
    backgroundImage: `url('${logoUrl}')`,
  }) : ({
      backgroundImage: `url('${FPIcon}')`, // Placeholder icon
  }); // TODO: Display placeholder icon if no logo

  const retailerName = parentOrganization || retailerDisplayName;

    return (
    <button className="retailerButton" onClick={handler}>
      <span className="image" style={buttonImageStyle} />
      <span className="name">
        {retailerName}
      </span>
    </button>
  );
};

export default RetailerButton;
