import React from 'react';
import {Link, useNavigate} from 'react-router-dom';

import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {dsp, updateDsp} from '../../../store/dspSlice';

import HeaderBar from '../../../shared/components/HeaderBar';
import SiteHeader from '../../../shared/components/SiteHeader';
import RetailerButton from '../../../shared/components/RetailerButton';

const SelectRetailer = () => {
  const dspData = useAppSelector(dsp);
  const dispatch = useAppDispatch();
  const {siteData, retailers} = dspData;
  const navigate = useNavigate();

  const retailerButtons: JSX.Element[] = [];

  retailers?.forEach((retailer, index) => {
    let multipleLocations = false;
  
    if (retailer.parentOrganization) {
      const additionalLocation = retailers.find((item) => {
        return (
          item.parentOrganization === retailer.parentOrganization &&
          item._id !== retailer._id
        );
      });

      if (additionalLocation) {
        multipleLocations = true;
        const additionalLocationIndex = retailers.findIndex((item) => {
          return (item._id === additionalLocation._id);
        });
        if (additionalLocationIndex > -1 && additionalLocationIndex < index) {
          // We've already added this retailer to our buttons
          return;
        }
      }
    }

    const goToUnitNumber = () => {
      dispatch(updateDsp({
        selectedRetailer: retailer,
        customRetailer: '', // Clear out any existing data
      }));
      if (siteData != null && siteData.siteType === 'APARTMENT')
        navigate('../unit-number');
      else
        navigate('../fresh-post-number');
    };

    const goToLocationSelect = () => {
      dispatch(updateDsp({
        selectedRetailer: retailer,
        customRetailer: '', // Clear out any existing data
      }));
      navigate('../select-location');
    }

    const handler = multipleLocations ? goToLocationSelect : goToUnitNumber;

    retailerButtons.push(
      <RetailerButton
        key={retailer._id}
        retailer={retailer}
        handler={handler}
      />
    );
  });

  return (
    <div className="viewContainer SelectRetailer">
      <div className="contentContainer">
        <HeaderBar />
        <SiteHeader site={siteData} />
        <main className="content">
          <div className="headingContainer">
            <p className="heading">
              Quickly deliver your order in a few easy steps!
            </p>
            <p className="subHeading">
              All you need is the FreshPost ID number included in the delivery address.
            </p>
          </div>
          <div className="retailerSelect">
            <p className="heading">
              Select the retailer you're delivering for
            </p>
            <div className="retailerList">
              {retailerButtons}
            </div>
            <p className="enterRetailerLink center">
              <Link to='../enter-retailer'>
                Not Listed?
              </Link>
            </p>
          </div>
        </main>
      </div>
    </div>
  );
};

export default SelectRetailer;
