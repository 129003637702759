import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './store';

interface AuthState {
  initialized: boolean; // Is the app fully initialized?
}

interface AuthUpdate {
  initialized?: boolean;
}

export const initialState: AuthState = {
  initialized: false,
};

/**
 * Please note that we're using the Redux Toolkit here,
 * which is allowing us to assign new values to state using
 * the Immer library underneath the hood.
 * https://redux-toolkit.js.org/usage/usage-guide
 */
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateAuth: (draftState, action: PayloadAction<AuthUpdate>) => {
      if (action.payload.initialized !== undefined) {
        draftState.initialized = action.payload.initialized;
      }
    },
  },
});

export const {updateAuth} = authSlice.actions;

export const appAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
