import React, { useEffect } from 'react';
import {Outlet, useNavigate} from 'react-router-dom';

import {useAppSelector} from '../../store/hooks';
import {dsp} from '../../store/dspSlice';

import {loadDspSiteData} from '../../services/dsp';
import {showLoader} from '../../shared/helpers/functions';

const DSP = () => {
  const dspData = useAppSelector(dsp);
  const {stationId, siteData} = dspData;
  const navigate = useNavigate();

  console.log('DSP State: ', dspData);
  
  useEffect(() => {
    if (stationId && !siteData) {
      showLoader(true);
      loadDspSiteData()
      .then((result) => {
        showLoader(false);
        const {success} = result;
        if (success) {
          navigate('select-retailer');
        } else {
          // TODO: Do we simply load the landing page
          // on error, or is there a difference between errors
          // (e.g. network error vs. ID not found)
          navigate('/landing');
        }
      });
    }
  }, [stationId, siteData, navigate]);

  return <Outlet />;
};

export default DSP;
