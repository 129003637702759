import {configureStore} from '@reduxjs/toolkit';
import authReducer from './authSlice';
import loaderSlice from './loaderSlice';
import alertSlice from './alertSlice';
import dspReducer from './dspSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    loader: loaderSlice,
    alert: alertSlice,
    dsp: dspReducer,
  },
});

// Infer root state and app dispatch types within the store itself
// https://redux.js.org/usage/usage-with-typescript
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
