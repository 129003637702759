import React from 'react';
import {createBrowserRouter} from 'react-router-dom';

import {store} from '../store/store';
import {updateDsp} from '../store/dspSlice';

import Root from './Root';
import RouterError from './RouterError';

// Public Views
import Landing from '../views/public/Landing/Landing';

// DSP Experience
import DSP from '../views/dsp/DSP';
import SelectRetailer from '../views/dsp/SelectRetailer/SelectRetailer';
import SelectRetailerLocation from '../views/dsp/SelectRetailerLocation/SelectRetailerLocation';
import EnterRetailer from '../views/dsp/EnterRetailer/EnterRetailer';
import EnterUnitNumber from '../views/dsp/EnterUnitNumber/EnterUnitNumber';
import SelectResident from '../views/dsp/SelectResident/SelectResident';
import SelectLockerType from '../views/dsp/SelectLockerType/SelectLockerType';
import LockerOpened from '../views/dsp/LockerOpened/LockerOpened';
import ManageLockers from '../views/dsp/ManageLockers/ManageLockers';
import OrderComplete from '../views/dsp/OrderComplete/OrderComplete';
import EnterFreshPostNumber from "../views/dsp/EnterFreshPostNumber/EnterFreshPostNumber";
import Finished from "../views/dsp/Finished/Finished";

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <RouterError />,
    children: [
      // Public views
      {
        path: 'landing',
        element: <Landing />,
      },
      // DSP experience
      {
        path: 'deliver',
        element: <DSP />,
        loader: (({request}) => {
          const url = new URL(request.url);
          const stationId = url.searchParams.get('station')?.toUpperCase()
          if (stationId) {
            store.dispatch(updateDsp({
              stationId,
            }));
          }
          return null;
        }),
        children: [
          {
            path: 'select-retailer',
            element: <SelectRetailer />,
          },
          {
            path: 'select-location',
            element: <SelectRetailerLocation />,
          },
          {
            path: 'enter-retailer',
            element: <EnterRetailer />,
          },
          {
            path: 'unit-number',
            element: <EnterUnitNumber />,
          },
          {
            path: 'fresh-post-number',
            element: <EnterFreshPostNumber />,
          },
          {
            path: 'select-resident',
            element: <SelectResident />,
          },
          {
            path: 'locker-type',
            element: <SelectLockerType />,
          },
          {
            path: 'locker-opened',
            element: <LockerOpened />,
          },
          {
            path: 'manage-lockers',
            element: <ManageLockers />,
          },
          {
            path: 'order-complete',
            element: <OrderComplete />,
          },
          {
            path: 'finished',
            element: <Finished />,
          },
        ],
      }
    ],
  },
]);
