import React from 'react';

import {Site} from '../../types/data';

interface SiteHeaderProps {
  site: Site | null;
}

const SiteHeader = (props: SiteHeaderProps) => {
  const {site} = props;
  const siteName = site?.displayName ?? '';
  const stationId = site?.stationId ?? '';

  return (
    <div className="siteHeader">
      {site && (
        <>
          <span className="name">
            {siteName}
          </span>
          <span className="station">
            Station ID: {stationId}
          </span>
        </>
      )}
    </div>
  );
};

export default SiteHeader;
