import React from 'react';

import Logo from '../../assets/images/freshpost-logo.png';

const HeaderBar = () => {
  // TODO: What does the "Need Help?" button do?

  return (
    <header className="headerBar">
      <div className="logoContainer">
        <img src={Logo} alt="FreshPost" />
      </div>
      <div className="rightOption">
        <button className="needHelpButton">
          Need Help?
        </button>
      </div>
    </header>
  );
};

export default HeaderBar;
