import React from 'react';
import {useLocation} from 'react-router-dom';

const ScrollToTop = () => {
  const location = useLocation();

  React.useEffect(() => {
    // On location change, scroll to top
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};

export default ScrollToTop;