import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {useAppSelector} from '../../../store/hooks';
import {dsp} from '../../../store/dspSlice';

import {LockerType} from '../../../types/data';

import HeaderBar from '../../../shared/components/HeaderBar';
import SiteHeader from '../../../shared/components/SiteHeader';
import DspRetailerHeading from '../../../shared/components/DspRetailerHeading';
import StandardButton from '../../../shared/components/StandardButton';
import StepCounter from '../../../shared/components/StepCounter';
import LockerTypeButton from '../../../shared/components/LockerTypeButton';

import {showAlert, showLoader} from '../../../shared/helpers/functions';
import {openNewLocker} from '../../../services/dsp';

const SelectLockerType = () => {
  const [processing, setProcessing] = useState(false);

  const dspData = useAppSelector(dsp);
  const {
    siteData,
    lockers,
    selectedRetailer,
    residentialUnitNumber,
    freshPostNumber,
    selectedResident
  } = dspData;
  const navigate = useNavigate();

  const lockerTypes: LockerType[] = [];
  lockers?.forEach((locker) => {
    const {lockerType} = locker;
    if (lockerType && !lockerTypes.includes(lockerType) && locker.status === 'IN SERVICE') {
      lockerTypes.push(lockerType);
    }
  });

  const handleLockerTypeButton = async (lockerType: LockerType) => {
    // TODO: Add loaders when API is in place
    // setProcessing(true);
    // showLoader(true);
    const result = await openNewLocker({lockerType});
    // setProcessing(false);
    // showLoader(false);

    const {success, message} = result;
    if (!success) {
      showAlert({
        visible: true,
        theme: 'error',
        message,
      });
      return;
    }

    navigate('../locker-opened');
  };

  const handleBack = () => {
    navigate(-1); // TODO: Updates to state?
  };

  let headingText = ""
  if (residentialUnitNumber !== "")
    headingText = `${selectedResident?.name} (Apt ${residentialUnitNumber})`;
  else
    headingText = `${selectedResident?.name} (FreshPost #: ${freshPostNumber})`;


  const lockerButtons = lockerTypes.map((type) => {
    const handler = () => handleLockerTypeButton(type);
    return (
      <LockerTypeButton
        key={type}
        action="open"
        theme={type}
        handler={handler}
        disabled={processing}
      />
    );
  });

  return (
    <div className="viewContainer SelectLockerType">
      <div className="contentContainer">
        <HeaderBar />
        <SiteHeader site={siteData} />
        <div className="currentStepContainer">
          <StepCounter currentStep={2} />
        </div>
        <main className="content">
          <DspRetailerHeading
            selectedRetailer={selectedRetailer}
            text={headingText}
          />
          <div className="lockerTypeButtons">
            {lockerButtons}
          </div>
          <p className="lockerSelectNote">
            You will have the opportunity to open additional lockers if you run out of space.
          </p>
        </main>
        <div className="actions">
          <div className="buttonContainer">
            <StandardButton 
              theme="secondary"
              label="Back"
              handler={handleBack}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectLockerType;
