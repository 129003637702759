import React from 'react';
import {useRouteError} from 'react-router-dom';

/**
 * Component displayed when an error bubbles up
 * to the React Router root.
 * TODO: Add styling based on design
 */
const RouterError = () => {
  const error = useRouteError();
  console.log(error);

  return (
    <div className="viewContainer">
      <div className="contentContainer">
        <main className="content">
          An error occurred, or the route could not be found.
        </main>
      </div>
    </div>
  );
};

export default RouterError;
