import React from 'react';

import {Retailer} from '../../types/data';

import FreshPostIcon from '../../assets/images/freshpost-icon.png';

interface DspRetailerHeadingProps {
  selectedRetailer: Retailer | null;
  text: string;
}

const DspRetailerHeading = (props: DspRetailerHeadingProps) => {
  const {selectedRetailer, text} = props;

  const retailerImageStyle = selectedRetailer?.logoUrl ? ({
    backgroundImage: `url('${selectedRetailer.logoUrl}')`,
  }) : ({});

  const retailerPlaceholder = selectedRetailer?.logoUrl ? null : (
    <span className="placeholder">
      <img src={FreshPostIcon} alt="FreshPost icon" />
    </span>
  );

  return (
    <div className="dspRetailerHeading">
      <div className="retailerImage" style={retailerImageStyle}>
        {retailerPlaceholder}  
      </div>
      <p>
        {text}
      </p>
    </div>
  );
};

export default DspRetailerHeading;
