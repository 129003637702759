import React, {useState} from 'react';

import {Retailer} from '../../types/data';

import {ChevronRight} from 'react-feather';

interface RetailerLocationButtonProps {
  retailer: Retailer;
  handler: () => void;
}

const RetailerLocationButton = (props: RetailerLocationButtonProps) => {
  const [distanceFromStation, setDistanceFromStation] = useState<number | null>(null);
  const {retailer, handler} = props;
  const {retailerDisplayName, logoUrl, physicalAddress} = retailer;

  // TODO: Determine distance from station
  // This will require lat/lng data for both the station and the retail location

  const buttonImageStyle = logoUrl ? ({
    backgroundImage: `url('${logoUrl}')`,
  }) : ({}); // TODO: Display placeholder icon if no logo

  return (
    <button className="retailerLocationButton" onClick={handler}>
      <span className="imageContainer">
        <span className="image" style={buttonImageStyle} />
      </span>
      <span className="infoContainer">
        <span className="info">
          <span className="nameAndDistance">
            <span className="name">
              {retailerDisplayName}
            </span>
            {distanceFromStation !== null && (
              <span className="distance">
                {distanceFromStation} mi
              </span>
            )}
          </span>
          <span className="address">
            {physicalAddress}
          </span>
        </span>
        <span className="icon">
          <ChevronRight />
        </span>
      </span>

    </button>
  );
};

export default RetailerLocationButton;
