import {store} from '../../store/store';
import {updateLoader} from '../../store/loaderSlice';
import {AlertState, updateAlert} from '../../store/alertSlice';

import {Locker, LockerColumn, LockerHeadingData, LockerType, Slot} from '../../types/data';

export const showLoader = (visible: boolean) => {
  store.dispatch(updateLoader({
    visible,
  }));
};

export const showAlert = (params: AlertState) => {
  store.dispatch(updateAlert(params));
};

export const dspSiteCheck = () => {
  const appState = store.getState();
  return appState.dsp.stationId;
};

/**
 * Get the hex value of a locker type hue
 * @param {LockerType} lockerType e.g. 'FREEZER'
 * @returns {string} e.g. '#4296D2'
 */
export const getLockerHue = (lockerType: LockerType) => {
  let hue;

  switch (lockerType) {
    case 'CHILLER':
      hue = '#9DCF76';
      break;
    case 'FREEZER':
      hue = '#4296D2';
      break;
    case 'AMBIENT':
      hue = '#98ADB6';
      break;
    default:
      hue = '#000000';
  }

  return hue;
}

/**
 * Helper function to build locker heading data
 * Assumes input column data is in numerical order by column number
 * @param {LockerColumn} columns 
 * @returns {LockerHeadingData}
 */
export const buildLockerHeadingData = (columns: LockerColumn[]) => {
  const lockerHeadingData: LockerHeadingData[] = [];

  columns.forEach((col) => {
    const {type, num} = col;
    
    // See if this type exists in lockerHeadingData
    const index = lockerHeadingData.findIndex((col) => {
      return col.type === type; 
    });

    if (index < 0) {
      lockerHeadingData.push({
        type,
        startCol: num,
        endCol: num,
      });
    } else {
      const update = {
        ...lockerHeadingData[index],
        endCol: num,
      }
      lockerHeadingData.splice(index, 1, update);
    }
  });

  return lockerHeadingData;
};

export const getSlotNumber = (width: number, row: number, col: number) => {
  if (col < 2) {
    return row * 2 + col + 1;
  } else {
    return (row * 2 + (col - 2)) + 7;
  }
}

// Check if a locker is opened
const isOpened = (slots: Slot[], row: number, col: number) => {
  for(let locker of slots) {
    if (locker.row === row && locker.column === col) return true;
  }
  return false;
};

export const lockerClass = (locker: any, lockerType: any, slots: Slot[], row: number, column: number) => {
  const classes = ['locker'];
  if (isOpened(slots, row, column)) {
    classes.push('opened');
  }

  if(lockerType === 'CHILLER') {
    classes.push('chiller');
  }

  if(lockerType === 'FREEZER') {
    classes.push('freezer');
  }
  return classes;
}

export const slotColor = (startingClass: any, locker: any, lockerType: any, slots: Slot[], row: number, column: number) => {
    const classes = [startingClass];

  if(lockerType === 'CHILLER') {
    classes.push('chiller');
  }

  if(lockerType === 'FREEZER') {
    classes.push('freezer');
  }

  return classes;
}
